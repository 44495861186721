<script>
export default {
  name: 'Checkbox',
  components: {
    CheckboxItem: () => import('@/components/general/CheckboxItem'),
    Icon: () => import('@/components/general/Icon'),
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },
  props: {
    description: {
      type: String,
      default: null
    },
    switchType: {
      type: Boolean,
      default: false
    },
    value: null,
    validation: {
      type: Object,
      default: () => ({})
    },
    items: {
      type: Array,
      default: () => ([])
    },
    itemsWarning: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    forceArray: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: null
    },
    tooltip: {
      type: [String, Boolean],
      default: false
    }
  },
  data () {
    return {
      mutableValue: [],
      mutableItems: this.items,
      emitInside: false
    }
  },
  computed: {
    isArray () {
      return this.items.length > 1 || this.forceArray
    },
    isRequired () {
      return this.validation.$params && typeof this.validation.$params.required === 'object'
    },
    hasUnique () {
      const item = this.items.find(item => item.unique)
      return item != null && this.mutableValue.indexOf(item.value.toString()) > -1
    }
  },
  watch: {
    value () {
      this.$emit('value', this.value)
      !this.emitInside && this.setValue()
      this.emitInside = false
    },
    disabled () {
      this.updateItems()
    },
    items () {
      this.mutableItems = this.items
      this.updateItems()
    }
  },
  created () {
    this.setValue()
    this.updateItems()
  },
  methods: {
    updateValue (item) {
      if (item.unique && item.checked) {
        this.mutableValue = []
        this.disableItemsNotUnique(true)
      } else if (item.unique && !item.checked) {
        this.disableItemsNotUnique(false)
      }
      this.$emit('change', item)
      typeof this.validation.$touch === 'function' && this.validation.$touch()
      item.checked ? this.addValue(item.value) : this.removeValue(item.value)
      this.emitInside = true
      this.$emit('input', this.isArray ? this.mutableValue : this.mutableValue.length === 1)
    },
    addValue (value) {
      this.mutableValue.push(value.toString())
    },
    removeValue (value) {
      const index = this.mutableValue.indexOf(value.toString())
      this.mutableValue.splice(index, 1)
    },
    setValue () {
      if (this.isArray) {
        this.mutableValue = this.value ? this.value : []
      } else if (this.value) {
        this.mutableValue = []
        this.addValue(this.value)
      } else if (this.value === false) {
        this.mutableValue = []
      }
    },
    checkValue (value) {
      return this.isArray ? this.mutableValue.indexOf(value.toString()) >= 0 : this.value
    },
    disableItemsNotUnique (status) {
      this.mutableItems = this.mutableItems.map(obj => {
        if (!obj.unique) {
          obj.disabled = status
        }
        return obj
      })
    },
    updateItems () {
      this.mutableItems = this.mutableItems.map(obj => {
        obj.disabled = obj.disabled ? obj.disabled : this.disabled ? this.disabled : this.hasUnique ? !obj.unique : false
        obj.unique = obj.unique ? obj.unique : false
        return obj
      })
    },
    labelLink (item) {
      this.$emit('label-link', item)
    }
  }
}
</script>

<template>
  <div
    v-tooltip.top-center="tooltip"
    class="form-item"
    :class="{ 'has-error': validation.$error, 'theme-dark': dark }"
    @click.stop
  >
    <p
      v-if="description"
      class="form-item-description"
    >
      {{ description }} <span v-if="!isRequired && !disabled">{{ $t('global:form.optional') }}</span>
    </p>
    <template v-for="(item, index) in mutableItems">
      <checkbox-item
        :key="index"
        :switch-type="switchType"
        :label="item.label"
        :description="item.description"
        :label-warning="itemsWarning[index] || null"
        :align-right="item.alignRight"
        :disabled="item.disabled"
        :readonly="item.readonly"
        :unique="item.unique"
        :checked="checkValue(item.value)"
        :value="item.value"
        @change="updateValue"
        @label-link="labelLink(item)"
      />
    </template>
    <div
      v-if="hint || validation.$error"
      class="form-input-details"
    >
      <span
        v-if="hint && !validation.$error"
        class="form-input-hint"
      >{{ hint }}</span>
      <validation-message :validation="validation" />
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/form.css"></style>
<style src="@/assets/styles/themes/default/checkbox.css"></style>
